'use strict';

(function($){
    $(document).ready(function(){
        if ( $('.sg-facebook-login-button').length === 0 ) {
           return;
        }

        function sgFBLogin( accesstoken ) {
            FB.api('/me', 'get',{ fields: 'id,name,email,first_name,last_name', access_token: accesstoken }, function(response) {
                var form = $('#giveasap_subscribe_form');
                var tokenField = form.find('[name=sg_fb_access_token]');
                if ( tokenField.length ) {
                    tokenField.val( accesstoken );
                } else {
                    form.append('<input type="hidden" name="sg_fb_access_token" value="' + accesstoken + '"/>');
                }

                var data = { action: 'sg_facebook_login', nonce: sg.nonce };
                form.find(':input').each( function(e) {
                    var input = $(this);
                    data[ input.attr('name' ) ] = input.val();

                    if ( 'checkbox' === input.attr('type') ) {
                        data[ input.attr('name' ) ] = input.prop('checked') ? '1' : '0';
                    }
                });

                form.addClass('sg-loading');
                $.ajax({
                    url: sg.ajaxurl,
                    data: data,
                    method: 'POST',
                    success: function( resp ) {
                        if ( resp.success ) {
                            window.location.href = resp.data.redirect;
                        } else {
                            alert( resp.data.message );
                        }
                    },
                    error: function( error, ajaxOptions, thrownError ) {
                        form.removeClass('sg-loading');
                        alert( thrownError );
                    },
                    complete: function() {
                        form.removeClass('sg-loading');
                    }
                });

            });
        }

        window.sgCheckFBLoginState = function() {
            FB.getLoginStatus(function(response) {
                if ( 'connected' === response.status ) {
                    var accesstoken = response.authResponse.accessToken;
                    sgFBLogin( accesstoken );
                } else {
                    FB.login(function(response) {
                        if (response.authResponse) {
                            sgFBLogin(FB.getAuthResponse().accessToken);

                        } else {
                            console.log('User cancelled login or did not fully authorize.');
                        }
                    });
                }
            });
        };

        (function(d, s, id) {
            var fb_locale = window.sg_fb_locale || 'en_US';
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/" + fb_locale + "/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        window.fbAsyncInit = function() {
            FB.init({
                appId      : sg.fb_app_id,
                cookie     : true,  // enable cookies to allow the server to access
                                    // the session
                xfbml      : true,  // parse social plugins on this page
                version    : 'v3.3' // The Graph API version to use for the call
            });
        };
    });
})(jQuery);