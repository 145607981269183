// import './jquery.plugin.min.js';
// import './jquery.countdown.min.js';
import ClipboardJS from './clipboard.min.js';
import './src/facebook.js';
import './slick.min.js';

(function($){
 
	$( document ).on(
		'click',
		'[data-giveaway-popup]',
		function(e){
			e.preventDefault();
			var giveawayId    = $( this ).attr( 'data-giveaway-popup' );
			var giveawayPopup = $( '#sg-giveaway-popup-' + giveawayId );
			if ( giveawayPopup.length ) {
				giveawayPopup.toggleClass( 'show' );
			}
		}
	);

	function sgUpdateEntries( entries, giveawayBox ) {
		var giveawayBox = giveawayBox || false;

		if ( ! giveawayBox ) {
			giveawayBox = $( '.giveasap-box' );
		}

		giveawayBox.find( '.giveasap-entries strong' ).html( entries );

		var prize_thresholds = giveawayBox.find('.giveasap-prize-thresholds');
		if ( prize_thresholds.length ) {
			$.ajax(
				{
					url: sg.ajaxurl,
					method: 'GET',
					data: {
						action: 'giveasap_get_prize_threshold',
						nonce: sg.nonce,
						giveaway: prize_thresholds.attr('data-giveaway'),
						subscriber: prize_thresholds.attr('data-subscriber'),
					},
					success: function (resp) {
						if ( resp.success ) {
							prize_thresholds.replaceWith( resp.data.html );
						}
					}
				}
			);
		}

	}

	if ( $( '.sg-giveaway-prizes-slider .sg-giveaway-prize' ).length > 1 ) {
		$( ".sg-giveaway-prizes-slider" ).slick(
			{
				// normal options...
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				dots: true
			}
		);
	}

	$( document ).on(
		'click',
		'.sg-extra-action-title, .sg-extra-action-close',
		function( e ){
			e.preventDefault();
			var actionTitle = $( this ).parents( '.sg-extra-action' ).find( '.sg-extra-action-title' ),
			expanded        = actionTitle.attr( 'aria-expanded' ) === 'true';

			actionTitle.attr( 'aria-expanded', ! expanded );

			$( this ).parents( '.sg-extra-action' ).toggleClass( 'expanded' );
		}
	);

	/**
	 * Add the Completed Action to a global object.
	 *
	 * @param giveaway_id
	 * @param instance_id
	 */
	function giveasap_add_completed_action( giveaway_id, instance_id ) {
		window.giveasap_completed_actions                = window.giveasap_completed_actions || {};
		window.giveasap_completed_actions[ giveaway_id ] = window.giveasap_completed_actions[ giveaway_id ] || [];
		if ( window.giveasap_completed_actions[ giveaway_id ].indexOf( instance_id ) < 0 ) {
			window.giveasap_completed_actions[ giveaway_id ].push( parseInt( instance_id ) );
		}
	}

	/**
	 * Check if the action is completed.
	 *
	 * @param giveaway_id
	 * @param instance_id
	 * @returns {boolean}
	 */
	function giveasap_is_action_completed( giveaway_id, instance_id ) {
		var completed_actions = window.giveasap_completed_actions || {};
		var giveaway_actions  = completed_actions[ giveaway_id ] || [];

		return giveaway_actions.indexOf( instance_id ) >= 0;
	}

	/**
	 * Check if an action can be completed due to number of completed actions.
	 *
	 * @param $action
	 * @param completed_count
	 * @returns {boolean}
	 */
	function giveasap_can_be_unlocked_with_completed_count( $action, completed_count ) {
		var required_completed = $action.attr( 'data-mandatory' );

		// No mandatory data at all. Must be a unlocked one.
		if ( ! required_completed ) {
			return true;
		}

		return parseInt( completed_count ) >= parseInt( required_completed );
	}

	/**
	 * Check if can be unlocked with completed specific actions.
	 * @param $action
	 * @param giveaway_id
	 * @returns {boolean}
	 */
	function giveasap_can_be_unlocked_with_completed_specific_actions( $action, giveaway_id ) {
		var specific_actions = $action.attr( 'data-mandatory_actions' );

		// No such attribute. It can be unlocked.
		if ( ! specific_actions ) {
			return true;
		}

		var instance_ids = specific_actions.split( ',' );
		var unlocked     = true;

		for( var u = 0; u < instance_ids.length; u++ ) {
			var id = instance_ids[u];
			if ( ! giveasap_is_action_completed( giveaway_id, parseInt( id ) ) ) {
				unlocked = false;
			}
		}

		return unlocked;

	}

	$( document ).on(
		'submit',
		'.sg-extra-action form',
		function(e){
			var $this   = $( this ),
			form        = $this,
			data        = $( this ).serialize(),
			processData = true,
			contentType = 'application/x-www-form-urlencoded',
			ajaxData    = {
				action: 'sg_extra_action_complete',
				nonce: sg.nonce,
				data: data,
			};

			if ( form.find( 'input[type=file]' ).length > 0 ) {
				if ( window.FormData ) {
					ajaxData = new FormData( form[0] );
					ajaxData.append( 'action', 'sg_extra_action_complete' );
					ajaxData.append( 'nonce', sg.nonce );
					ajaxData.append( 'data', data );
					processData = false;
					contentType = false;
				}
			}

			form.addClass( 'sg-loading' );

			$.ajax(
				{
					url: sg.ajaxurl,
					method: 'POST',
					contentType: contentType,
					processData: processData,
					data: ajaxData,
					success: function( resp ) {
						if ( resp.success ) {
							var html          = resp.data.complete_mark || '',
							entries           = resp.data.entries || false,
							action_html       = resp.data.html || false,
							actions_completed = resp.data.actions_completed || false;

							if ( html ) {
								var title = form.parent().find( '.sg-extra-action-title' );
								title.find( '.sg-extra-action-entries' ).remove();
								title.append( html );
							}

							if ( action_html ) {
								form.find( '.sg-extra-action-form-body' ).html( action_html );
							}

							if ( entries ) {
								var gBox = form.parents( '.giveasap-box' );

								if ( gBox.length === 0 ) {
									gBox = form.parents( '.giveasap_box' );
								}
								sgUpdateEntries( entries, gBox );
							}

							var instance_id = $this.find( '[name=instance]' ).val();
							var giveaway_id = $this.find( '[name=giveaway_id]' ).val();

							giveasap_add_completed_action( giveaway_id, instance_id );

							if ( actions_completed ) {

								var actions        = form.parents( '.giveasap_extra_actions' ),
									locked_actions = actions.find( '.sg-extra-action.sg-extra-action-locked' );

								locked_actions.each(
									function (){
										var $this = $( this );

										// Can't be completed yet.
										if ( ! giveasap_can_be_unlocked_with_completed_count( $this, actions_completed ) ) {
											return true;
										}

										if ( ! giveasap_can_be_unlocked_with_completed_specific_actions( $this, giveaway_id ) ) {
											return true;
										}

										$this.removeClass( 'sg-extra-action-locked' );
									}
								);
							}

							$this.find( 'button[name=sg_extra_action_submit]' ).remove();
						} else {
							alert( resp.data );
						}
					},
					complete: function() {
						form.removeClass( 'sg-loading' );
					}
				}
			);

			return false;
		}
	);

	$( document ).on(
		'click',
		'.sg_extra_action_button[data-check-action]',
		function(e){
			var $this = $( this ),
			form      = $this.parents( 'form' ),
			data      = form.serialize(),
			value     = $this.attr( 'data-check-action' );

			form.addClass( 'sg-loading' );
			$.ajax(
				{
					url: sg.ajaxurl,
					data: {
						action: 'sg_extra_action_check',
						nonce: sg.nonce,
						data: data,
						value: value
					},
					success: function( resp ) {
						if ( resp.success ) {
							if ( typeof resp.data !== 'undefined' && typeof resp.data.redirect !== 'undefined' && resp.data.redirect ) {
								window.location.href = resp.data.redirect;
							} else {
								form.find( '[name=sg_extra_action_submit]' ).removeAttr( 'disabled' );
							}
						} else {
							alert( resp.data );
						}
					},
					complete: function() {
						form.removeClass( 'sg-loading' );
					}
				}
			);
		}
	);

	// We are ready!
	$(
		function(){

			var $countdown = $( "#countdown" ),
			$countdowns    = $( ".giveasap_countdown" );
			if ( $.countdown.defaultOptions.labels.indexOf( 'Years' ) >= 0 ) {
				$.countdown.setDefaults(
					{
						labels: [
						sg.text.countdown.labels.years,
						sg.text.countdown.labels.months,
						sg.text.countdown.labels.weeks,
						sg.text.countdown.labels.days,
						sg.text.countdown.labels.hours,
						sg.text.countdown.labels.minutes,
						sg.text.countdown.labels.seconds
						],
						labels1: [
						sg.text.countdown.labels1.year,
						sg.text.countdown.labels1.month,
						sg.text.countdown.labels1.week,
						sg.text.countdown.labels1.day,
						sg.text.countdown.labels1.hour,
						sg.text.countdown.labels1.minute,
						sg.text.countdown.labels1.second
						],
						compactLabels: [
						sg.text.countdown.compactLabels.y,
						sg.text.countdown.compactLabels.m,
						sg.text.countdown.compactLabels.w,
						sg.text.countdown.compactLabels.d,
						]
					}
				);
			}

			if ( $countdown.length ) {
				var $timestamp = $countdown.attr( "data-end" );
				var $timezone  = $countdown.attr( "data-timezone" );

				$countdown.countdown(
					{
						until: new Date( $timestamp * 1000 ),
						format: 'dHMS'}
				);
			}

			if ( $countdowns.length ) {
				$countdowns.each(
					function(){
						var $timestamp = $( this ).attr( "data-end" );
						var $timezone  = $( this ).attr( "data-timezone" );
						$( this ).countdown(
							{
								until: new Date( $timestamp * 1000 ),
								format: 'dHMS'}
						);
					}
				);
			}

			if ( $( '.giveasap.template3 .giveasap_box.step-1' ).length > 0 ) {
				var height = $( '.giveasap_aside' ).outerHeight( true ) + 100;

				$( '.giveasap_box' ).css( 'margin-bottom', height + 'px' );
			}
		}
	);

	$( "#giveasap_show_rules" ).on(
		'click',
		function(){
			$( ".giveasap_rules_extended" ).toggleClass( "active" );
		}
	);

	$( "#giveasap_request_link" ).on(
		'click',
		function(e){
			$( this ).parents( 'form' ).addClass( 'hidden' );
			$( '#giveasap_request_link_form' ).removeClass( 'hidden' );
		}
	);

	$( '.sg-toggle' ).on(
		'click',
		function (e) {
			e.preventDefault();
			var $this = $( this ),
			$target   = $this.attr( 'data-target' ),
			$class    = $this.attr( 'data-class' ),
			$text     = $this.attr( 'data-text' );

			$( $target ).toggleClass( $class );
			if ( $text ) {
				var old_text = $this.text();
				$this.attr( 'data-text', old_text );
				$this.html( $text );
			}
		}
	);

	$( document ).on(
		'click',
		'.sg-twitter-login-button a',
		function(e){
			if ( $( '#giveasap_consent' ).length && ! $( '#giveasap_consent' ).prop( 'checked' ) ) {
				e.preventDefault();
				alert( sg.text.accept_terms || 'Please accept terms and conditions' );
			}
		}
	);

	$( document ).on(
		'click',
		'.giveasap_sharing .sharing_method a',
		function(e){
			var href = $( this ).attr( 'href' ),
			popup    = $( this ).attr( 'data-popup' );

			$( document.body ).triggerHandler( 'giveasap_sharing_method_clicked', [ $( this ) ] );

			if ( typeof popup !== 'undefined' && popup === '1' ) {
				e.preventDefault();

				var popupWindow = window.open(
					href,
					'popUpWindow',
					'height=300,width=400,left=50,top=50,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes'
				);
			}
		}
	);

	if ( $( '.giveasap_link' ).length && ClipboardJS.isSupported() ) {
		var val = $( '.giveasap_link input' ).val();
		$( '.giveasap_link' ).append( '<button data-clipboard-text="' + val + '" class="sg-btn-copy" type="button"><span class="fal fa-copy"></span><span class="sg-btn-copy-text">Copy</span></button>' );
		var clipboard = new ClipboardJS( '.sg-btn-copy' );
		clipboard.on(
			'success',
			function(e) {
				var icon = $( '.sg-btn-copy .fal' );
				icon.removeClass( 'fa-copy' ).addClass( 'fa-check' );
				setTimeout(
					function(){
						icon.removeClass( 'fa-check' ).addClass( 'fa-copy' );
					},
					2000
				);
			}
		);

	}

})( jQuery );
